import {
  AppBar,
  Box,
  Button,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/styles";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import { MdOutlineWallet } from "react-icons/md";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import mobileLogoImage from "src/assets/images/x.svg";
import AccountContext from "src/contexts/AccountContext";
import LayoutContext from "src/contexts/LayoutContext";
import useAuth from "src/hooks/useAuth";
import AccountMenu from "./dashboard/AccountMenu";
import EyeShowSensitiveInfo from "./EyeShowSensitiveInfo";
import Logo from "./Logo";
import MenuSelectAccount from "./menus/MenuSelectAccount";
import MenuTimezone from "./menus/MenuTimezone";
import SelectAccountSkeleton from "./skeleton/SelectAccountSkeleton";
import SubHeaderDense from "./trade/SubHeaderDense";

const MobileLogo = styled("img")({
  height: 20,
  // marginRight: 10,
});

const IconsStyle = {
  cursor: "pointer",
  "&:hover": {
    color: "#CCC",
  },
};

const HeaderDashboard: FC = (props) => {
  const { t } = useTranslation(["dashboard", "home"]);
  const auth = useAuth();
  const { accounts, activeAccount } = useContext(AccountContext);
  const location = useLocation();
  const { setActiveTab } = useContext(LayoutContext);
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const tab = searchParams.get("tab");

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <AppBar
      sx={{
        background: isMobile ? "#050b0e" : "#00060A",
        flexGrow: 1,
        pl: 1,
        boxShadow: "none",
        borderBottom: "1px solid #010e16",
      }}
    >
      <Toolbar
        variant="dense"
        disableGutters={true}
        sx={{ minHeight: isMobile ? 60 : 80 }}
      >
        {isMobile && (
          <RouterLink
            to={`${auth.isAuthenticated ? "/traderoom" : "/"}`}
            onClick={() => setActiveTab("dashboard")}
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
          >
            <MobileLogo
              src={mobileLogoImage}
              style={{
                marginRight: location.pathname !== "/traderoom" ? 0 : 10,
              }}
              alt=""
            />
          </RouterLink>
        )}
        <Stack
          direction="row"
          justifyContent={isMobile ? "space-around" : "space-between"}
          alignSelf="center"
          spacing={isMobile ? 0 : 2}
          sx={{
            width: "100%",
            ml: { xs: 0, lg: "5px" },
            mr: { lg: "5px" },
          }}
        >
          <Box
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {!isMobile && (
              <RouterLink
                to={`${auth.isAuthenticated ? "/traderoom" : "/"}`}
                onClick={() => setActiveTab("dashboard")}
                style={{}}
              >
                <Logo
                  sx={{
                    width: isMobile ? "102px" : "150px", // Aumentado em 50%
                  }}
                />
              </RouterLink>
            )}

            <>
              {location.pathname !== "/traderoom" ? (
                <Button
                  sx={{ mr: "auto", color: "#fff" }}
                  onClick={() => {
                    window.location.href = "/traderoom";
                  }}
                  color="inherit"
                  // startIcon={<ArrowBack sx={{ width: "16px" }} />}
                >
                  <Typography
                    sx={{
                      fontSize: isMobile ? "12px" : "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IoIosArrowBack
                      style={{ marginTop: isMobile ? "initial" : "2px" }}
                    />
                    Traderoom
                  </Typography>
                </Button>
              ) : (
                <SubHeaderDense />
              )}
            </>
          </Box>

          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
            alignItems="center"
            alignSelf="center"
            sx={{
              pr: isMobile ? 1 : 2,
              gap: isMobile && "0.2rem",
              justifyContent: "flex-end",
              width: isMobile ? "auto" : undefined,
              marginLeft: isMobile ? "auto" : undefined,
            }}
          >
            {(!isMobile || location.pathname !== "/traderoom") && (
              <MenuTimezone />
            )}
            {!isMobile && (
              <Box sx={IconsStyle}>
                <EyeShowSensitiveInfo size={20} />
              </Box>
            )}
            <Box sx={{ marginLeft: "0 !important" }}>
              {accounts.length > 0 && activeAccount ? (
                <MenuSelectAccount />
              ) : (
                <SelectAccountSkeleton />
              )}
            </Box>

            {tab !== "deposit" && (
              <Button
                color="primary"
                component={RouterLink}
                size="large"
                variant="contained"
                to="/dashboard/profile?tab=deposit"
                style={{ marginLeft: "0" }}
                sx={{
                  marginLeft: "0",
                  width: isMobile ? "auto" : "143px",
                  minWidth: isMobile ? 40 : undefined,
                  height: isMobile ? 40 : "3.5rem",
                  px: isMobile ? 0 : undefined,
                  py: isMobile ? 0 : undefined,
                  "& .MuiButton-root": {
                    bonderRadius: "8px",
                  },
                  background:
                    "linear-gradient(135deg, #795912 0%, #DFA422 50%, #997118 100%)",
                }}
              >
                <MdOutlineWallet
                  size={isMobile ? 24 : 24}
                  style={{ marginRight: !isMobile && 4 }}
                />
                {!isMobile && t("deposit")}
              </Button>
            )}

            <AccountMenu openTemporaryModalFunction={handleOpen} />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderDashboard;
