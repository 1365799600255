import { SxProps, Theme, styled } from "@mui/material/styles";
import type { FC } from "react";
import logoEbinexCristmas from "src/assets/images/ebinex_new_year_logo.svg";

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = styled("img")({});

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot src={logoEbinexCristmas} height="52" {...props} />
);

export default Logo;
