import { Box, Typography } from "@mui/material";
import CgTimelapse from "../icons/CgTimelapse";

export default function ChipRetraction({ text }: { text: string }) {
  return (
    <Box
      sx={{
        height: 25,

        backgroundColor: "#00000050",
        borderRadius: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 1,
        paddingLeft: 1,
        paddingTop: 2,
        paddingBottom: 2,
        gap: 1,
      }}
    >
      <CgTimelapse />
      <Typography variant="caption">{text}</Typography>
    </Box>
  );
}
